/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

import {
  Button,
  Row,
  Col,
  Card,
  Space,
  Table,
  TablePaginationConfig,
  Tag,
  TableProps,
  Select,
  Input,
  Empty,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import SiteService from '../../../APIServices/SiteService';
import { useClient } from '../../../Context/ClientContextProvider';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { SiteType } from '../../../models/SiteType';
import { convertDate } from '../../../utils/ConvertDate';
import ClearFilter from '../../ClearFilter';

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

interface tableDataProps {
  tableData?: SiteType[];
  columnParams?: ColumnsType<SiteType>;
  searchFields?: { id: string; value: string }[];
  isCustomer?: boolean;
  tableAction?: (site: string) => void;
  siteSelector?: boolean;
  customerId?: string;
}

const ClientSitesTable: React.FC<tableDataProps> = ({
  tableData,
  columnParams,
  searchFields,
  isCustomer,
  siteSelector,
  tableAction,
  customerId,
}) => {
  const [loadingTable, setLoadingTable] = useState(false);
  const { singleClient } = useClient();
  const [searchField, setSearchField] = useState('Select Field');
  const { Option } = Select;
  const { Search } = Input;
  const client = sessionStorage.getItem('customer');
  const axiosPrivate = useAxiosPrivate();
  const [dropdownItems, setDropdownItems] = useState([
    { id: 'ncrSiteId', value: 'NCR Site ID' },
    { id: 'branchNo', value: 'Branch Number' },
  ]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [filters, setFilters] = useState('');
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const getSingleSiteView = (siteID: string) => {
    navigate(`${siteID}`);
  };

  const columns: ColumnsType<SiteType> = [
    {
      title: 'NCR Site ID',
      dataIndex: 'ncrSiteId',
      key: 'ncrSiteId',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Branch No.',
      dataIndex: 'branchNo',
      key: 'branchNo',
    },

    {
      title: 'Address Line 1',
      dataIndex: 'addressLine1',
      key: 'addressLine1',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: 'Geotag Coordinates',
      dataIndex: 'geotag',
      key: 'geotag',
      render: (record) => {
        return record[0].concat(', ', record[1]);
      },
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (time) => {
        const datetime = new Date(time);
        return datetime.toLocaleDateString();
      },
    },
    {
      title: 'View',
      key: 'view',
      fixed: 'right',
      render: (_, record) => {
        if (siteSelector) {
          return (
            <Space size="middle">
              <Button
                size="small"
                type="primary"
                onClick={() => tableAction!(record.id!)}
              >
                Select
              </Button>
            </Space>
          );
        }
        return (
          <Space size="middle">
            <Button
              size="small"
              type="primary"
              onClick={() => getSingleSiteView(record.id!)}
            >
              View
            </Button>
          </Space>
        );
      },
    },
  ];

  const handleSearch = (e: any) => {
    const encodedValue = encodeURIComponent(e.trim());
    setPage(0);
    setSize(10);
    setFilters(`${searchField}=${encodedValue}`);
  };

  const { data: clientSites, isLoading } = useQuery(
    ['client-sites', page, size, filters],
    () =>
      SiteService.getSites(
        page,
        size,
        filters,
        sessionStorage.getItem('customer')!
      )
  );

  const handleChange = (pagination: any) => {
    setPage(pagination.current - 1);
    setSize(pagination.pageSize);
  };

  const selectBefore = (
    <div>
      <Select
        defaultValue="Select Field"
        placeholder="Select Field"
        value={searchField}
        onChange={(e) => setSearchField(e)}
        style={{ width: 200 }}
        getPopupContainer={(trigger) => trigger.parentElement}
      >
        {dropdownItems?.map((item: any) => {
          return (
            <Option key={item.id} value={item.id}>
              {item.value}
            </Option>
          );
        })}
      </Select>
    </div>
  );

  const clearFilters = () => {
    setSearchField('Select Field');
    setSearchValue('');
    setFilters('');
  };

  return (
    <Row>
      <Col span={24}>
        <Card title="Sites" className="h-full">
          <div className="search-bar">
            <Search
              addonBefore={selectBefore}
              placeholder="Search"
              enterButton="Search"
              value={searchValue}
              size="large"
              prefix={
                searchField !== 'Select Field' && (
                  <ClearFilter clearFilters={clearFilters} />
                )
              }
              onChange={(e) => setSearchValue(e.target.value)}
              onSearch={(e) => handleSearch(e)}
            />
          </div>
          <Table
            columns={columnParams || columns}
            rowKey={(record) => record.id!}
            dataSource={clientSites?.data}
            loading={loadingTable}
            onChange={handleChange}
            locale={{
              emptyText: <Empty description="No sites" />,
            }}
            className="mt-8"
            pagination={{
              defaultPageSize: 10,
              current: page + 1,
              pageSize: size,
              total: Number(clientSites?.headers['x-total-count']),
              pageSizeOptions: ['10', '20', '50', '100'],
              showSizeChanger: true,
            }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default ClientSitesTable;

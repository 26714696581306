import React, { useEffect, useState } from 'react';

import {
  DownloadOutlined,
  FileTextOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import {
  Card,
  Button,
  Col,
  Row,
  Table,
  Tag,
  Tooltip,
  notification,
  Alert,
  Empty,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useQuery, useQueryClient } from 'react-query';

import CSVService from '../../../APIServices/CSVService';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { LogType } from '../../../models/LogType';
import {
  convertDate,
  convertMillisecondsToDHMS,
} from '../../../utils/ConvertDate';

type recentDownloadsType = {
  name: string;
  fileData: any;
};
interface DownloaderProps {
  tab?: string;
}

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const Reporting: React.FC<DownloaderProps> = ({ tab }) => {
  const axiosPrivate = useAxiosPrivate();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [total, setTotal] = useState('0');
  const [api, contextHolder] = notification.useNotification();
  const [currentRequests, setCurrentRequests] = useState<any>([]);
  const [requestId, setRequestId] = useState<any>('');

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description,
      duration: 20,
    });
  };

  const {
    data: recentReports,
    isLoading,
    refetch: recallRecentReports,
  } = useQuery(['recentReports', page, pageSize], () =>
    CSVService.getDownloadLogs(page, pageSize, '&fileType=new_scan_site_report')
      .then((response) => {
        setTotal(response.headers['x-total-count']);
        return response.data.filter((log: LogType) => {
          return log.id !== requestId.logId;
        });
      })
      .then((response) => {
        return response;
      })
  );

  const requestExtract = () => {
    axiosPrivate({
      method: 'GET',
      url: `/impex/export/unknown-site-geo-scan-report`,
    })
      .then((response) => {
        setCurrentRequests([response.data]);
        setRequestId(response.data.logId);
        recallRecentReports();
        openNotificationWithIcon(
          'success',
          'Success',
          'Site report requested successfully, extract may take a while to complete.'
        );
        return response.data;
      })
      .catch((error) => {
        openNotificationWithIcon(
          'error',
          'Error',
          'Site report request failed, please try again or contact support.'
        );
        console.log(error, 'error');
      });
  };

  const downloadExtract = (logId: string, fileName: string) => {
    axiosPrivate({
      method: 'GET',
      url: `/impex/import/logs/${logId}/srcfile`,
      responseType: 'blob',
    })
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName); // any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
        openNotificationWithIcon('success', 'Success', 'Download successful.');
      })
      .catch((error) => {
        openNotificationWithIcon(
          'error',
          'Error',
          'Download failed, some files may be archived and not available for download anymore.'
        );
        console.log(error, 'error');
      });
  };

  const columns: ColumnsType<LogType> = [
    {
      title: 'Status',
      dataIndex: 'progressStatus',
      key: 'progressStatus',
      render: (_, record) => {
        let color = 'green';
        let text;
        if (record.progressStatus === 'FilePersisted') {
          color = 'slateBlue';
          text = 'Accepted';
        }
        if (record.progressStatus === 'NonStarted') {
          color = 'darkGray';
          text = 'Not Started';
        }
        if (record.progressStatus === 'InProgress') {
          color = 'gold';
          text = 'In Progress';
        }
        if (record.progressStatus === 'Completed') {
          color = 'green';
          text = 'Completed';
        }
        if (record.progressStatus === 'Aborted') {
          color = 'red';
          text = 'Aborted';
        }
        if (record.progressStatus === 'Paused') {
          color = 'orange';
          text = 'Paused';
        }
        return (
          <>
            {' '}
            <Tag color={color}>{text}</Tag>
            {record.failedCount > 0 && (
              <Tag color="red">{text} with Errors</Tag>
            )}
          </>
        );
      },
    },
    {
      title: 'File Name',
      dataIndex: 'srcFile',
      key: 'srcFile',
      render: (text) => {
        if (text === undefined) {
          return 'Not Available';
        }
        return text;
      },
    },
    {
      title: 'Execution Time',
      dataIndex: 'executionTime',
      key: 'executionTime',
      render: (time) => {
        return (
          <Tag color="default">
            {time ? convertMillisecondsToDHMS(time) : 'Not Available'}
          </Tag>
        );
      },
    },
    {
      title: 'File Type',
      dataIndex: 'fileType',
      key: 'fileType',
      render: (fileType) => {
        if (fileType === 'new_scan_site_report') {
          return 'new scan site report	';
        }

        return fileType;
      },
    },
    {
      title: 'Requested By',
      dataIndex: 'uploadedBy',
      key: 'uploadedBy',
    },
    {
      title: 'Requested At',
      dataIndex: 'uploadedAt',
      key: 'uploadedAt',
      render: (time) => {
        return convertDate(time);
      },
    },
    {
      title: 'Download',
      key: 'action',
      render: (_, record) => {
        if (record.progressStatus === 'Completed') {
          return (
            <Button
              onClick={() => {
                downloadExtract(record.id, record.summaryFile);
              }}
            >
              <DownloadOutlined />
            </Button>
          );
        }
        return <>Not Currently Available</>;
      },
    },
  ];

  const handleChange = (pagination: any) => {
    setPage(pagination.current - 1);
    setPageSize(pagination.pageSize);
  };

  return (
    <Row gutter={20}>
      {contextHolder}
      <Col lg={24} xl={6}>
        <Card
          title="Request Potential Site Report"
          className="downloader-container"
        >
          {currentRequests.length !== 0 && (
            <div className="alert-download">
              <Alert
                message="Request accepted, please check back for updates. Some requests may take a while to complete."
                type="success"
                showIcon
              />
              <br />
            </div>
          )}
          <div>
            <Button
              onClick={() => requestExtract()}
              className="download-item"
              type="link"
            >
              <div className="icon-box">
                <FileTextOutlined />
              </div>
              <div className="icon-data">
                <h3>Request Report</h3>
              </div>
              <div className="icon-download">
                <SwapOutlined />
              </div>
            </Button>
          </div>
        </Card>
      </Col>
      <Col lg={24} xl={18}>
        <Card
          title="Reports"
          style={{ marginBottom: 22 }}
          extra={
            <Button type="primary" onClick={() => recallRecentReports()}>
              Refresh
            </Button>
          }
        >
          <Table
            columns={columns}
            loading={isLoading}
            dataSource={recentReports}
            rowKey={(record) => record.id!}
            onChange={handleChange}
            locale={{
              emptyText: <Empty description="No Reports" />,
            }}
            pagination={{
              defaultPageSize: 5,
              total: Number(total),
              pageSizeOptions: ['5', '10', '20', '50', '100'],
              showSizeChanger: true,
            }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default Reporting;

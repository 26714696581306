import { axiosPrivate } from '../api/axios';
import { type CustomerMapType } from '../models/CustomerMap';

export default class CustomerMapService {
  static async getCustomerMap(
    page: number,
    size: number,
    isDeleted: string,
    filters?: string
  ) {
    return axiosPrivate({
      url: `/customer-map${filters ? `?${filters}` : ''}`,
      method: 'GET',
      params: {
        page,
        size,
        isdeleted: isDeleted,
      },
    });
  }

  static async update(customerMap: CustomerMapType) {
    return axiosPrivate.put(`/customer-map`, [customerMap]);
  }

  static async delete(customerMap: CustomerMapType) {
    return axiosPrivate.delete(`/customer-map`, {
      data: [customerMap],
    });
  }

  static async add(customerMap: CustomerMapType) {
    return axiosPrivate.post(`/customer-map`, [customerMap]);
  }

  static async getCustomerMapLookup() {
    return axiosPrivate.get('/customer-map/lookup-data');
  }

  static async postAutomationRule(confirm: boolean) {
    return axiosPrivate.post('/customer-map/sync');
  }

  static async getServiceMapByServiceNbr(serviceNbr: string | undefined) {
    if (!serviceNbr) {
      return Promise.resolve({
        data: [],
      });
    }
    return axiosPrivate.get(
      `/customer-map/lookup-data/${serviceNbr as string}`
    );
  }
}

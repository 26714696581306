import React, { useEffect } from 'react';

import { Button, Space } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';

type DataFiltersProps = {
  tableRefresh: () => void;
  clearFilters: () => void;
  setIsDeleted: React.Dispatch<React.SetStateAction<string>>;
};

const DataFilters = ({
  tableRefresh,
  clearFilters,
  setIsDeleted,
}: DataFiltersProps) => {
  const [active, setActive] = React.useState(true);

  const handleActive = () => {
    setIsDeleted('false');
    clearFilters();
    setActive(true);
  };

  const handleInActive = () => {
    setIsDeleted('true');
    clearFilters();
    setActive(false);
  };

  return (
    <Space size="middle" align="baseline" className="w-fit flex justify-end">
      <ButtonGroup>
        <Button
          type={active ? 'primary' : 'dashed'}
          size="large"
          onClick={() => handleActive()}
          shape="round"
        >
          Active
        </Button>
        <Button
          type={!active ? 'primary' : 'dashed'}
          size="large"
          onClick={() => handleInActive()}
          className="rounded-md"
          shape="round"
        >
          Inactive
        </Button>
      </ButtonGroup>
    </Space>
  );
};

export default DataFilters;
